import {Injectable} from '@angular/core';
import * as _ from 'lodash';

import {ApiService} from './api.service';
import {ProFormaModel} from '../models/proformaModel';
import {ExtendedProforma, IOnHold, IProformaSaveModel, ProForma, ProFormaDownload} from './data/proforma';
import {ListingProperty} from './data/listingPropety';
import { Preferences } from '../models/preferences';
import { PropertyStatusType } from './data/propertyStatusType';
import {ListingComps} from './data/listingComps';
import {ListingResult} from './data/listingResult';
import {PhotoItem} from './data/photoItem';
import {DateRangeFilter, DateRangeFormatFilter} from "./data/dateRangeFilter";
import { ITag } from './data/tags';
import { TagsUtils } from './tagUtils';
import { EVBUS_EVENTS_NAMES } from './update-request-service/model/updateRequest';
import {ITargetOption} from "../panels/proforma/proforma";
import {EntityUtils} from "./entityUtils";
import { IViewSetting, ViewMode } from '../ui/view-setting/viewSettingModel';
import * as underwriterUtils from '../utils/underwriterUtils';

export interface GridRequestParams {
  selectedColumns: string[];
  viewMode: ViewMode;
  buyBoxId: string;
  groupKeys: string[];
  dateRange: DateRangeFilter;
  viewSetting: IViewSetting;
  fromLine: any;
  filters: any;
  sort: any;
  update: any;
  fetchTMUser: any;
  getCount: any;
  address: string;
  listResult?: ListingProperty[];
}
export abstract class UnderwriterAbstractService  {
  /**
   *
   * @param params
   */
  protected abstract getAllListings(params: GridRequestParams): Promise<ListingResult>;
  /**
   *
   */
  abstract getActiveTags(): void;
  /**
   *
   * @param params
   */
  abstract getListings(params: GridRequestParams): Promise<ListingResult>;
  /**
   *
   */
  abstract reloadEntities (): void;

  /**
   * This method will first check in the current "buy box" cached listing to find the property
   * If not found, the server will then be queried to get it.
   * @param aoListingId The id of the property
   */
  abstract getListingProperty(aoListingId: any, viewMode: ViewMode): Promise<ListingProperty>;
  /**
   *
   * @param aoListingId
   * @param flowId
   */
  abstract getProforma(aoListingId: any, flowId?): Promise<ProFormaModel>;
  /**
   *
   * @param proformaModel
   * @param avmProformaModel
   * @param versioning
   * @param evBusEvents
   * @param lastUpdateDate
   * @param workflowState
   */
  abstract saveProforma(proformaModel: ProFormaModel, avmProformaModel: ProFormaModel, versioning: any, evBusEvents: EVBUS_EVENTS_NAMES[], lastUpdateDate, workflowState: string): Promise< { onHold?: IOnHold, updatedProperty?: ListingProperty, proformaModel?: ProFormaModel, viewMode?: ViewMode } >;

  /**
   *
   * @param aoListingId
   * @param date
   */
  abstract downloadProforma(aoListingId: any, date: any): Promise<ProFormaDownload>;
  /**
   *
   */
  abstract getPreferences(): Promise<Preferences>;

  /**
   *
   * @param newPreferences
   */
  abstract savePreferences(newPreferences?): Promise<Preferences>;
  /**
   *
   * @param properties
   * @param message
   * @param forceUpdateOnConflict
   */
  abstract hidePropertyListings(properties: ListingProperty[], message: string, forceUpdateOnConflict?: boolean): Promise<any>;

  /**
   *
   * @param listingName
   * @param filters
   */
  abstract updateFilters(listingName: string, filters: any): Promise<Preferences>;

  /**
   *
   * @param properties
   * @param forceUpdateOnConflict
   * @param status
   */
  abstract savePropertyStatus(properties: ListingProperty[], viewMode: ViewMode, forceUpdateOnConflict: boolean, status?: PropertyStatusType): Promise<any>;

  /**
   *
   * @param property
   * @param data
   */
  abstract saveProperty(property: ListingProperty, data: any): Promise<any>;

  /**
   *
   * @param aoListingId
   * @param proformaId
   * @param filters
   */
  abstract getListingComparables(aoListingId: any, proformaId: any, filters: any): Promise<ListingComps[]>;

  /**
   *
   * @param aoListingId
   * @param aoCompId
   * @param aoPhotoGroupId
   */
  abstract getComparablePhotos(aoListingId: any, aoCompId: any, aoPhotoGroupId: any): Promise<any[]>;

  /**
   *
   * @param aoListingId
   */
  abstract getListingPropertyPhotos(aoListingId: any): Promise<PhotoItem[]>;

  /**
   *
   */
  abstract getTagsList(): Promise<any>;

  /**
   *
   * @param tagData
   */
  abstract updateTags(tagData: ITag): Promise<any>;

  /**
   *
   */
  abstract getPurchasingEntities(): Promise<ITargetOption[]>;

  /**
   *
   */
  abstract refreshEnteties(): Promise<any>;











}
