import { Injectable } from '@angular/core';

import { ConfigService } from './config.service';
import { AwsCredentialsService } from './awsCredentials.service';
import { Sigv4Http } from './sigv4.service';
import { Preferences } from '../models/preferences';
import {ExtendedProforma, IProformaNetYield, IProformaSaveModel, ProForma} from './data/proforma';
import { ListingProperty } from './data/listingPropety';
import { PropertyStatusType } from './data/propertyStatusType';
import { AuditItem } from './data/auditItem';
import { ListingComps } from './data/listingComps';
import { ListingResult } from './data/listingResult';
import { PhotoItem } from './data/photoItem';
import {S3Document, S3FileReference} from './data/document';
import { UserRolesPermissions } from '../roles-permissions/types';
import { INote, IQueryNote, noteAction } from './data/notes';
import {DateRangeFormatFilter} from "./data/dateRangeFilter";
import { ITag } from './data/tags';
import { EVBUS_EVENTS_NAMES } from './update-request-service/model/updateRequest';
import { IViewSetting, ViewMode } from '../ui/view-setting/viewSettingModel';

export interface ListingsParams {
  selectedColumns: string[];
  viewMode: ViewMode;
  buyBoxId: string;
  groupKeys: string[];
  dateRange: DateRangeFormatFilter;
  viewSetting: IViewSetting;
  fromLine: number;
  filters: any;
  sort: any;
  update: boolean;
  fetchTMUser: boolean;
  getCount: boolean;
  address: string;
}

@Injectable()
export abstract class ApiService {

  constructor(
    protected awsCredentialsService: AwsCredentialsService,
    protected configService: ConfigService,
    protected sigv4: Sigv4Http
  ) {}

  // abstract login(email: string, password: string);
  abstract resetPassword(email: string);
  abstract resetChangePassword(email: string, id?: string, password?: string): Promise<any>;
  abstract changePassword(password?: string): Promise<any>;
  abstract postAccept( email: string, id: string, password: string): Promise<any>;

  abstract getListings(params: ListingsParams): Promise<ListingResult>;
  abstract getListingProperty(aoListingId: string, viewMode: ViewMode): Promise<ListingProperty>;

  abstract downloadFilteredProperties(viewMode: ViewMode, buyBoxId: string, groupKeys: string[], dateRange: DateRangeFormatFilter, viewSetting: IViewSetting, fromLine: number, limit: number,filters: any, sort: any, update: boolean, fetchTMUser: boolean, getCount: boolean): Promise<any[]>;

  abstract getSignedURL(fileName: string): Promise<any>;

  abstract getRolesPermissions(): Promise<UserRolesPermissions>;
  abstract getCoverageData(): Promise<any>;
  abstract getZipCodeCoverage(zipCode: string): Promise<any>;

  abstract getPreferences(): Promise<Preferences>;
  abstract savePreferences(preferences: Preferences): Promise<Preferences>;

  abstract getProforma(aoListingId: any, flowId?): Promise<ExtendedProforma>;
  abstract saveProforma(proforma: ProForma,avmProforma: ProForma, userInputProforma: ProForma, message: string, reason: string, listingStatus: PropertyStatusType, selectedComparableIds: any[], listingData: any, versioning, evBusEvents: EVBUS_EVENTS_NAMES [], workFlowNote: any, sendToTransactionManager: boolean, pendingEvents: any, lastUpdateDate: string, workflowState: string, viewMode: ViewMode): Promise<IProformaSaveModel>;
  abstract downloadProforma(aoListingId: any, date: any): Promise<any>;

  abstract hidePropertyListings(props: {AOListingID: string | number, StatusUpdateDate: string}[], message: string, forceUpdateOnConflict?: boolean): Promise<any>;
  abstract savePropertyStatus(props: {AOListingID: string | number, StatusUpdateDate: string, status: PropertyStatusType}[],viewMode: ViewMode, forceUpdateOnConflict?: boolean): Promise<any>;
  abstract saveProperty(aoListingId: any, data: any): Promise<any>;

  abstract getListingPropertyAudit(aoListingId: any): Promise<AuditItem[]>;
  abstract getAuditHistory(aoListingId: any, source: string, avmProforma: boolean): Promise<AuditItem[]>;

  abstract getListingPropertyPhotos(aoListingId: any): Promise<PhotoItem[]>;

  abstract getListingComparables(aoListingId: any, proformaId: any, filters: any): Promise<ListingComps[]>;
  abstract getComparablePhotos(aoListingId: any, aoCompId: any, aoPhotoGroupId: any): Promise<any[]> ;

  abstract getFileFromS3(aoListingId, filepath): Promise<any>;
  abstract uploadFileToS3(document: S3Document): Promise<boolean>;

  abstract getNotes(aoListingId: any): Promise<INote[]>;
  abstract getNotificationsCount(aoListingId: any): Promise<number>;
  abstract postNotes(action: noteAction, noteQuery: IQueryNote): Promise<any>;
  abstract updateNotifications(noteQuery: IQueryNote[]): Promise<any>;
  abstract downloadProperties(aoListingIds: number[], viewMode: ViewMode): Promise<any[]>;
  abstract getTags(): Promise<any>;
  abstract updateTags(tagData: ITag): Promise<any>;
  abstract getVersion(): Promise<any>;
  abstract getFlags(): Promise<any>;
  abstract getPurchasingEntities(): Promise<any>;
  abstract getActiveTags(): Promise<any>;
  abstract getBulkPortfolioNames(): Promise<any>;
  abstract getClassification(AOListingID: number, rent: number): Promise<any>;
  abstract getNetYield(AOListingID: string, request: IProformaNetYield): Promise<any>;

  abstract downloadFilteredComps( aoListingID: string, proformaId: string, filters: any, sortModel: any): Promise<any>;
  abstract submitBulkUpload(bulkPortfolioName: string, bulkModelType: string, fileReference: S3FileReference): Promise<any>;
}
