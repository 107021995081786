import { Injectable } from '@angular/core';

import { ConfigService } from './config.service';
import { Sigv4Http } from './sigv4.service';
import { AwsCredentialsService } from './awsCredentials.service';
import {ApiService, ListingsParams} from './api.service';
import { HttpClient } from '@angular/common/http';
import { AppInjector } from '../app.injector';
import { Preferences } from '../models/preferences';
import {ExtendedProforma, IProformaNetYield, IProformaSaveModel, ProForma} from './data/proforma';
import { ListingProperty } from './data/listingPropety';
import { PropertyStatusType } from './data/propertyStatusType';
import { AuditItem } from './data/auditItem';
import { ListingComps } from './data/listingComps';
import { ListingResult } from './data/listingResult';
import { PhotoItem } from './data/photoItem';
import { S3Document } from './data/document';

import * as _ from 'lodash';
import { UserRolesPermissions } from '../roles-permissions/types';
import { INote, IQueryNote, noteAction } from './data/notes';
import { ITag } from './data/tags';
import { EVBUS_EVENTS_NAMES } from './update-request-service/model/updateRequest';
import { IViewSetting, ViewMode } from '../ui/view-setting/viewSettingModel';

@Injectable()
export class ApiMockService extends ApiService {
  http: HttpClient;

  listingCache = [];


  constructor(
    protected awsCredentialsService: AwsCredentialsService,
    protected configService: ConfigService,
    protected sigv4: Sigv4Http,
  ) {
    super(awsCredentialsService, configService, sigv4);

    this.http = AppInjector.get(HttpClient);

  }

  private getJson<T>(path): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const fullPath = './assets/mock/' + path;

      this.http.get<T>(fullPath,
        {
          observe: 'response'
        })
        .subscribe(getResponse => {
          resolve(getResponse.body);

        }, error => {
          console.log(error);
          resolve(null);
        });
    });
  }



  resetPassword(email: string) { }
  resetChangePassword(email: string, id?: string, password?: string): Promise<any> { return null; }
  changePassword(password?: string): Promise<any> { return null; }
  postAccept( email: string, id: string, password: string = null ): Promise<any> { return null; }

  getListings(params: ListingsParams): Promise<ListingResult> {
    const {buyBoxId} = params;
    return new Promise<ListingResult>((resolve) => {

      this.getJson<ListingProperty[]>(`listing_${buyBoxId}.json`)
      .then(result => {
        const ret = new ListingResult();
        ret.hasMore = false;
        ret.listings = result;

        this.listingCache = result;
        resolve(ret);
      });

    });
  }

  downloadFilteredProperties(viewMode: ViewMode, buyBoxId: string, groupKeys: string[], fromDate: any, viewSetting: IViewSetting, fromLine: number, limit: number, filters: any, sort: any, update: boolean, fetchTMUser: boolean, getCount: boolean): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {

      this.getJson<ListingProperty[]>(`listing_${buyBoxId}.json`)
      .then(result => {
        resolve(result);
      });

    });
  }
  getSignedURL(fileName: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      resolve(fileName);

    });
  }

  getListingProperty(aoListingId: string, viewMode: ViewMode): Promise<ListingProperty> {
    return new Promise<ListingProperty>((resolve, reject) => {

      resolve(_.find(this.listingCache, (l) => l.AOListingID == aoListingId ));
    });
  }

  getRolesPermissions(): Promise<UserRolesPermissions> {
    return new Promise<UserRolesPermissions>((resolve) => {
      this.getJson<UserRolesPermissions>(`roles-permissions.json`)
      .then(result => {
        resolve(result);
      });
    });
  }

  getCoverageData(): Promise<any> {
    return new Promise<UserRolesPermissions>((resolve) => {
      this.getJson<UserRolesPermissions>(`coverage.json`)
      .then(result => {
        resolve(result);
      });
    });
  }

  getZipCodeCoverage(zipCode): Promise<any> {
    return new Promise<UserRolesPermissions>((resolve) => {
      this.getJson<UserRolesPermissions>(`coverage.json`)
      .then(result => {
        resolve(result);
      });
    });
  }

  getPreferences(): Promise<Preferences> {
    return new Promise<Preferences>((resolve, reject) => {
      this.getJson<Preferences>(`preferences.json`)
      .then(result => {
        resolve(result);
      });
    });
  }

  savePreferences(preferences: Preferences): Promise<Preferences> {
    return new Promise<Preferences>((resolve, reject) => {

      resolve(preferences);
    });
  }

  getProforma(aoListingId: any): Promise<ExtendedProforma> {
    return new Promise<ExtendedProforma>((resolve, reject) => {

      resolve(new ExtendedProforma());
    });
  }


  saveProforma(proforma: ProForma, avmProforma: ProForma, userInputProforma: ProForma, message: string, reason: string, listingStatus: PropertyStatusType, selectedComparableIds: any[], listingData: any, versioning, evBusEvents: EVBUS_EVENTS_NAMES [], workFlowNote: any, sendToTransactionManager: boolean, pendingEvents: any, lastUpdateDate: string, workflowState: string, viewMode: ViewMode): Promise<IProformaSaveModel> {

    return new Promise<IProformaSaveModel>((resolve, reject) => {

      resolve({} as IProformaSaveModel);
    });
  }

  downloadProforma(aoListingId: any, date: any): Promise<any> {
    return new Promise<ProForma>((resolve, reject) => {
      resolve(null);
    });
  }

  hidePropertyListings(props: {AOListingID: string, StatusUpdateDate: string}[], message: string, forceUpdateOnConflict): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      resolve(true);
    });
  }

  savePropertyStatus(props: {AOListingID: string | number, StatusUpdateDate: string, status: PropertyStatusType}[], viewMode: ViewMode, forceUpdateOnConflict?: boolean): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      resolve(true);
    });
  }

  saveProperty(status: PropertyStatusType, aoListingIds: string[] | Number[]): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      resolve(true);
    });
  }

  getListingPropertyAudit(aoListingId: any): Promise<AuditItem[]> {
    return new Promise<AuditItem[]>((resolve, reject) => {
      resolve([]);
    });
  }



  getAuditHistory(aoListingId: any, source: string, avmProforma: boolean): Promise<AuditItem[]> {
    return new Promise<AuditItem[]>((resolve, reject) => {
      resolve([]);
    });
  }

  getListingPropertyPhotos(aoListingId: any): Promise<PhotoItem[]> {
    return new Promise<PhotoItem[]>((resolve, reject) => {
      resolve([]);
    });
  }

  getListingComparables(aoListingId: any, proformaId: any, filters: any): Promise<ListingComps[]> {
    return new Promise<ListingComps[]>((resolve, reject) => {
      resolve([]);
    });
  }

  getComparablePhotos(aoListingId: any, aoCompId: any, aoPhotoGroupId: any): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      resolve([]);
    });
  }

  uploadFileToS3(document: S3Document): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      resolve(false);
    });
  }

  getFileFromS3(aoListingId, filepath): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      resolve(null);
    });
  }

  getNotes(aoListingId: any): Promise<INote[]> {
    return new Promise<INote[]>((resolve) => {
      resolve(null);
    });
  }

  getNotificationsCount(aoListingId: any): Promise<number> {
    return new Promise<number>((resolve) => {
      resolve(null);
    });
  }

  postNotes(action: noteAction, noteQuery: IQueryNote): Promise<any> {
    return new Promise<any>((resolve) => {
      resolve(null);
    });
  }

  updateNotifications(noteQuery: IQueryNote[]): Promise<any> {
    return new Promise<any>((resolve) => {
      resolve(null);
    });
  }

  downloadProperties(): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      resolve(null);
    });
  }

  getTags(): Promise<any[]> {
      return this.getJson<any[]>(`listing_tags.json`);
  }

  updateTags(tagData: ITag): Promise<any> {
    return this.getJson<any[]>(`updated_tags.json`);
  }

  getVersion(): Promise<any> {
    return new Promise<any[]>((resolve) => {
      resolve(null);
    });
  }

  getFlags(): Promise<any> {
    return new Promise<any[]>((resolve) => {
      resolve(null);
    });
  }

  getPurchasingEntities(): Promise<any> {
    return new Promise<any[]>((resolve) => {
      resolve(null);
    });
  }

  getClassification(AOListingID: number, rent: number): Promise<any> {
    return new Promise<any[]>((resolve) => {
      resolve(null);
    });
  }

  getNetYield(AOListingID: string, request: IProformaNetYield): Promise<any> {
    return new Promise<any[]>((resolve) => {
      resolve(null);
    });
  }

  getActiveTags(): Promise<any> {
    return new Promise<any[]>((resolve) => {
      resolve(null);
    });
  }

  getBulkPortfolioNames(): Promise<any> {
    return new Promise<any[]>((resolve) => {
      resolve(null);
    });
  }

  downloadFilteredComps(aoListingID: string, proformaId: string, filters: any, sort: any): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      this.getJson<ListingProperty[]>(`listing_${aoListingID}_comps.json`)
      .then(result => {
        resolve(result);
      });

    });
  }

  submitBulkUpload(bulkPortfolioName: string, bulkModelType: string, fileReference: S3Document): Promise<any> {
    return new Promise<any[]>((resolve) => {
      resolve(null);
    });
  }
}
